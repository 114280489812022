/** Action Creators:
 *  https://redux.js.org/basics/actions#action-creators
 */

import {
  CLEAR_VIEWPORT,
  SET_ACTIVE_SPECIFIC_DATA, SET_STUDY, SET_STUDY_CONVERSION_STATUS,
  SET_CURRENT_PAGE, SET_REPORT_WINDOW, SET_SERVERS, SET_VIEWPORT,
  SET_VIEWPORT_ACTIVE,
  SET_VIEWPORT_LAYOUT,
  SET_VIEWPORT_LAYOUT_AND_DATA,
  CLEAR_VIEWER_DATA
} from './constants/ActionTypes.js';

/**
 * VIEWPORT
 */
export const setViewportSpecificData = (viewportIndex, data) => ({
  type: SET_VIEWPORT,
  viewportIndex,
  data,
});

export const setViewportActive = (viewportIndex) => ({
  type: SET_VIEWPORT_ACTIVE,
  viewportIndex,
});

/**
 * @param {object} layout
 * @param {number} layout.numRows
 * @param {number} layout.numColumns
 * @param {array} layout.viewports
 */
export const setLayout = ({ numRows, numColumns, viewports }) => ({
  type: SET_VIEWPORT_LAYOUT,
  numRows,
  numColumns,
  viewports,
});

/**
 * @param {object} layout
 * @param {number} layout.numRows
 * @param {number} layout.numColumns
 * @param {array} layout.viewports
 */
export const setViewportLayoutAndData = (
  { numRows, numColumns, viewports },
  viewportSpecificData
) => ({
  type: SET_VIEWPORT_LAYOUT_AND_DATA,
  numRows,
  numColumns,
  viewports,
  viewportSpecificData,
});

export const clearViewportSpecificData = (viewportIndex) => ({
  type: CLEAR_VIEWPORT,
  viewportIndex,
});

export const setActiveViewportSpecificData = (data) => ({
  type: SET_ACTIVE_SPECIFIC_DATA,
  data,
});

/**
 * NOT-VIEWPORT
 */
export const setStudyLoadingProgress = (progressId, progressData) => ({
  type: 'SET_STUDY_LOADING_PROGRESS',
  progressId,
  progressData,
});

export const clearStudyLoadingProgress = (progressId) => ({
  type: 'CLEAR_STUDY_LOADING_PROGRESS',
  progressId,
});

export const setUserPreferences = (state) => ({
  type: 'SET_USER_PREFERENCES',
  state,
});

export const setExtensionData = (extension, data) => ({
  type: 'SET_EXTENSION_DATA',
  extension,
  data,
});

export const setTimepoints = (state) => ({
  type: 'SET_TIMEPOINTS',
  state,
});

export const setMeasurements = (state) => ({
  type: 'SET_MEASUREMENTS',
  state,
});

export const setStudyData = (studyInstanceUid, data) => ({
  type: 'SET_STUDY_DATA',
  studyInstanceUid,
  data,
});

export const setServers = (servers) => ({
  type: SET_SERVERS,
  servers,
});


/**
 * VIEWER
 */
export const clearViewerData = () => ({
  type: CLEAR_VIEWER_DATA
});

export const setCurrentPage = (newPage) => ({
  type: SET_CURRENT_PAGE,
  payload: newPage,
});

export const setReportWindow = (data) => ({
  type: SET_REPORT_WINDOW,
  data,
});

export const setStudy = (data) => ({
  type: SET_STUDY,
  data,
});

export const setStudyConversionStatus = (studyInstanceUid, currentStatus, originalStatus = null) => ({
  type: SET_STUDY_CONVERSION_STATUS,
  payload: { studyInstanceUid, currentStatus, originalStatus }
});


const actions = {
  // VIEWPORT
  setViewportActive,
  setViewportSpecificData,
  setViewportLayoutAndData,
  setLayout,
  clearViewportSpecificData,
  setActiveViewportSpecificData,
  setStudyLoadingProgress,
  clearStudyLoadingProgress,
  setUserPreferences,
  setExtensionData,
  setTimepoints,
  setMeasurements,
  setStudyData,
  setServers,

  // VIEWER
  clearViewerData,
  setCurrentPage,
  setReportWindow,
  setStudy,
  setStudyConversionStatus
};

export default actions;
