import OHIF from '@ohif/core';
import {Dropdown} from '@ohif/ui';
import {Button, Collapse, DatePicker, Icon as IconAntd, Popover} from 'antd';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {PaginationArea} from './PaginationArea.js';
import './StudyList.styl';
import {StudyListLoadingText} from './StudyListLoadingText.js';
import {StudylistToolbar} from './StudyListToolbar.js';

const {RangePicker} = DatePicker;
const {Panel} = Collapse;

const today = moment();
const lastWeek = moment().subtract(7, 'day');
const lastMonth = moment().subtract(1, 'month');

function getPaginationFragment(props, searchData, nextPageCb, prevPageCb, changeRowsPerPageCb) {

  return (
    <PaginationArea
      pageOptions={props.pageOptions}
      currentPage={props.currentPage}
      nextPageFunc={nextPageCb}
      prevPageFunc={prevPageCb}
      onRowsPerPageChange={changeRowsPerPageCb}
      rowsPerPage={searchData.rowsPerPage}
      recordCount={props.studies.length}
    />
  );
}

function getTableMeta(translate) {
  return {
    patientId: {
      displayText: translate('MRN'),
      enabled: true,
      sort: 0,
    },
    patientNameLast: {
      displayText: 'Patient Last Name',
      placeholder: '',
      enabled: true,
      sort: 0,
    },
    patientName: {
      headerText: 'Patient Name',
      displayText: 'Patient First Name',
      placeholder: '',
      enabled: true,
      sort: 0,
    },
    dob: {
      displayText: translate('DOB'),
      placeholder: 'MM/DD/YYYY',
      enabled: true,
      sort: 0,
    },
    studyDescription: {
      displayText: translate('StudyDescription'),
      enabled: true,
      inputType: 'dropdown',
      sort: 0,
      propsVariable: 'studyTypes'
    },
    // facility: {
    //   displayText: translate('Facility'),
    //   enabled: false,
    //   sort: 0,
    // },
    studyDate: {
      displayText: translate('StudyDate'),
      placeholder: 'MM/DD/YYYY',
      enabled: true,
      inputType: 'date-range',
      sort: 0,
    },
    studyTime: {
      displayText: translate('StudyTime'),
      placeholder: '',
      enabled: true,
      inputType: 'none',
      sort: 0,
    },
    studyDateTime: {
      displayText: translate('StudyDateTime'),
      placeholder: '',
      enabled: true,
      inputType: 'none',
      sort: 0,
    },
    facility: {
      displayText: translate('Facility'),
      placeholder: '',
      enabled: false,
      //inputType: 'date-range',
      sort: 0,
    },
    actions: {
      displayText: translate(''),
      placeholder: '',
      enabled: false,
      inputType: 'none',
      sort: 0,
    },
  };
}

function getNoListFragment(translate, studies, error, loading) {
  if (loading) {
    return (
      <div className="loading">
        <StudyListLoadingText />
      </div>
    );
  } else if (error) {
    return <div className="notFound">{translate('There was an error fetching studies')}</div>;
  } else if (!studies.length) {
    return <div className="notFound">{translate('No matching results')}</div>;
  }
}

class StudyList extends Component {
  static propTypes = {
    studies: PropTypes.array.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    worklists: PropTypes.array,
    facilities: PropTypes.array,
    statuses: PropTypes.array,
    onSearch: PropTypes.func.isRequired,
    t: PropTypes.func,
    currentPage: PropTypes.number,
    rowsPerPage: PropTypes.number,
    studyListDateFilterNumDays: PropTypes.number,
    studyListFunctionsEnabled: PropTypes.bool,
    defaultSort: PropTypes.shape({
      field: PropTypes.string.isRequired,
      order: PropTypes.oneOf(['desc', 'asc']).isRequired,
    }),
    onImport: PropTypes.func,
    pageOptions: PropTypes.array,
    selectedWorklist: PropTypes.number,
    selectedStatus: PropTypes.number,
    setStatus: PropTypes.func,
    selectFacilityWorklistStatus: PropTypes.func,
    loading: PropTypes.bool,
    children: PropTypes.node,
  };

  static defaultProps = {
    currentPage: 0,
    rowsPerPage: 10,
    studyListDateFilterNumDays: 7,
  };

  static studyDatePresets = [
    {
      text: 'Today',
      start: today,
      end: today,
    },
    {
      text: 'Last 7 days',
      start: lastWeek,
      end: today,
    },
    {
      text: 'Last 30 days',
      start: lastMonth,
      end: today,
    },
  ];

  static advancedSearch = [
    {
      field: 'referringPhysician',
      label: 'Referring Physician',
      placeholder: 'All Physician',
      options: ['doe', 'smith', 'trevor'],
    },
    {
      field: 'reviewPhysician',
      label: 'Reviewing Physician',
      placeholder: 'All Physician',
      options: ['doe', 'smith', 'trevor'],
    },
    {
      field: 'technician',
      label: 'Technician',
      placeholder: 'All technician',
      options: ['doe', 'smith', 'trevor'],
    },
  ];

  constructor(props) {
    super(props);

    const sortData = {
      field: undefined,
      order: undefined,
    };

    // init from props
    if (props.defaultSort) {
      sortData.field = props.defaultSort.field; // todo: -1, 0, 1?
      sortData.order = props.defaultSort.order; // asc, desc
    }

    this.defaultStartDate = null;
    this.defaultEndDate = null;

    this.state = {
      loading: false,
      error: false,
      searchData: {
        sortData,
        currentPage: this.props.currentPage,
        rowsPerPage: this.props.rowsPerPage,
        patientId: '',
        patientName: '',
        patientNameLast: '',
        dob: '',
        accessionNumber: '',
        studyDescription: '',
        facility: '',
        physician: '',
        status: '',
        type: '',
        studyDate: [],
        studyDateLoaded: '',
      },
      highlightedItem: '',
    };

    this.getChangeHandler = this.getChangeHandler.bind(this);
    this.getBlurHandler = this.getBlurHandler.bind(this);
    this.onInputKeydown = this.onInputKeydown.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);
    this.delayedSearch = debounce(this.search, 250);

    this.refreshStudyList = 0;
  }

  componentDidMount = () => {
    this.refreshStudyList = setInterval(() => {this.search(true)}, 15000);

    var search = sessionStorage.getItem('search');

    // Per Jeff's request, when technicians log in, the studylist they're presented should be of studies with status ID 1 (Examined)
    // TODO: remove this temporary filter of status type
    if (JSON.parse((atob(sessionStorage.getItem('token').split('.')[1])))['roleName'] === 'Tech') {
      this.props.setStatus(1);
      this.selectDropdown('selectedStatusID', 1);
    }

    if (search) {
      search = JSON.parse(search);

      this.setState({
        searchData: {
          sortData: search.sortData,
          currentPage: search.currentPage,
          rowsPerPage: search.rowsPerPage,
          patientId: search.patientId,
          patientName: search.patientName,
          patientNameLast: search.patientNameLast,
          dob: search.dob,
          accessionNumber: search.accessionNumber,
          studyDescription: search.studyDescription,
          facility: search.facility,
          physician: search.physician,
          status: search.status,
          type: search.type,
          studyDate: search.studyDate.map((x) => moment(x)),
          studyDateLoaded: search.studyDateLoaded,
          studyDateFrom: search.studyDateFrom,
          studyDateTo: search.studyDateTo
        },
      });
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.refreshStudyList);
    this.refreshStudyList = 0;
  }

  // handleChangeViewerCache = (e) => {
  //   const cache = e.target.id;

  //   if (cache === 'fast') {
  //     window.cornerstone.imageCache.setMaximumSizeBytes(25073741824); // 25 GB
  //     window.cornerstoneWADOImageLoader.compressedImageCache.setMaximumSizeBytes(1073741824); // default 1 GB
  //   } else {
  //     window.cornerstone.imageCache.setMaximumSizeBytes(1073741824); // default 1 GB
  //     window.cornerstoneWADOImageLoader.compressedImageCache.setMaximumSizeBytes(25073741824); // 25 GB
  //   }

  //   sessionStorage.setItem('cache', cache);
  //   this.setState({browserCache: cache});
  // };

  getChangeHandler(key) {
    return (event) => {
      this.setSearchData(key, event.target.value);
    };
  }

  getBlurHandler(key) {
    return (event) => {
      this.delayedSearch.cancel();
      this.setSearchData(key, event.target.value);
    };
  }

  setSearchData(key, value, performSearch = false) {
    const searchData = {...this.state.searchData};
    searchData[key] = value;

    if (!isEqual(searchData[key], this.state.searchData[key])) {
      this.setState({...this.state, searchData}, function () {
        if (performSearch) {
          this.search();
        }
      });
    } else {
      if (performSearch) {
        this.search();
      }
    }
  }

  handleDateRangeChange = (momentValues, stringValues) => {
    const searchData = Object.assign({}, this.state.searchData);
    searchData.studyDate = momentValues;

    this.setState({searchData}, function () {
      this.setSearchDataBatch(
        {
          studyDateFrom:
            momentValues.length > 0 ? moment(momentValues[0]).format('MM/DD/YYYY') : '',
          studyDateTo: momentValues.length > 0 ? moment(momentValues[1]).format('MM/DD/YYYY') : '',
        },
        true
      );
    });
  };

  setSearchDataBatch(keyValues, performSearch = false) {
    const searchData = {...this.state.searchData};

    Object.keys(keyValues).forEach((key) => {
      searchData[key] = keyValues[key];
    });

    this.setState({searchData}, function () {
      if (performSearch) {
        this.search();
      }
    });
  }

  async onInputKeydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      this.delayedSearch.cancel();
      // reset the page because user is doing a new search
      this.setSearchData('currentPage', 0, true);
    }
  }

  async onSearchClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.delayedSearch.cancel();
    // reset the page because user is doing a new search
    this.setSearchData('currentPage', 0, true);
  }

  async search(isInterval = false) {
    if (isInterval && (this.props.currentPage !== 0)) {
      return;
    }

    try {
      await this.props.onSearch(this.state.searchData);
    } catch (error) {
      this.setState({error: true});
      throw new Error(error);
    }
  }

  nextPage(currentPage) {
    currentPage = currentPage + 1;
    this.delayedSearch.cancel();
    this.setSearchData('currentPage', currentPage, true);
  }

  prevPage(currentPage) {
    currentPage = currentPage - 1;
    this.delayedSearch.cancel();
    this.setSearchData('currentPage', currentPage, true);
  }

  onRowsPerPageChange(rowsPerPage) {
    this.delayedSearch.cancel();
    this.setSearchDataBatch({rowsPerPage, currentPage: 0}, true);
  }

  onSortClick(field) {
    // These sorts do not work because we do not have column for them in database.
    if (field === 'studyDateLoaded') {
      return;
    }

    let order;
    const sort = this.state.searchData.sortData;
    const isSortedField = sort.field === field;

    if (isSortedField) {
      if (sort.order === 'asc') {
        order = 'desc';
      } else {
        if (this.props.defaultSort.field !== sort.field) {
          order = this.props.defaultSort.order;
          field = this.props.defaultSort.field;
        } else {
          order = 'asc';
        }
      }
    } else {
      order = 'asc';
    }

    // Reset back to first page on new sort.
    const searchData = this.state.searchData;
    searchData.currentPage = 0;

    this.setState({searchData}, function () {
      this.delayedSearch.cancel();
      this.setSearchData('sortData', {field, order}, true);
    });
  }

  onHighlightItem(studyItemUid) {
    this.setState({highlightedItem: studyItemUid});
  }

  getTableCell(study, studyKey, emptyValue = '', emptyClass = '') {
    const componentKey = `td${studyKey}`;
    const isValidValue = study && typeof study[studyKey] === 'string';
    let className = emptyClass;
    let value = emptyValue;

    let displayValue = study[studyKey];

    if (studyKey === 'patientBirthdate' && study[studyKey]) {
      displayValue = `${study[studyKey].substring(4, 6)}/${study[studyKey].substring(6, 8)}/${study[studyKey].substring(0, 4)}`
    } else if (studyKey === 'patientName' && study[studyKey]) {
      displayValue = study[studyKey].split('^').map(x => x.toUpperCase()).join(', ');
    } else if (studyKey === 'studyTime' && study[studyKey]) {
      if (!study[studyKey].includes('/')) {
        study[studyKey] = `1970-01-01 ${study[studyKey]}`
      }
      displayValue = moment(study[studyKey]).format('hh:mm A')
    }

    if (isValidValue) {
      className = studyKey;
      value = displayValue;
    }

    return (
      <td
        key={componentKey}
        className={className}
        onMouseDown={(event) => {
          // middle/wheel click
          if (event.button === 1) {
            this.props.onSelectItem(study.studyInstanceUid);
          }
        }}
        onClick={() => {
          this.onHighlightItem(study.studyInstanceUid);
          this.props.onSelectItem(study.studyInstanceUid);
        }}>
        {value}
      </td>
    );
  };

  getTableRow(study, index) {

    const trKey = `trStudy${index}${study.studyInstanceUid}`;

    if (!study) {
      return;
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return (
        <tr
          key={trKey}
          className={
            this.state.highlightedItem === study.studyInstanceUid
              ? 'studylistStudy noselect active'
              : 'studylistStudy noselect'
          }
          onMouseDown={(event) => {
            // middle/wheel click
            if (event.button === 1) {
              this.props.onSelectItem(study.studyInstanceUid);
            }
          }}
          onClick={() => {
            this.onHighlightItem(study.studyInstanceUid);
            this.props.onSelectItem(study.studyInstanceUid);
          }}
        >
          {this.getTableCell(study, 'patientId')}
          {this.getTableCell(study, 'patientName', `(${this.props.t('Empty')})`, 'emptyCell')}
          {this.getTableCell(study, 'studyDescription')}
        </tr>
      );
    } else {
      return (
        <tr
          //onClick={
          //  () => {
          //    var myWindow = window.open('', 'MyWindowName');
          //    if (myWindow.location.href === 'about:blank') {
          //      myWindow.close();
          //    } else {
          //      myWindow.location.href = `/report/${study.studyInstanceUid}`
          //    }
          //  }
          //}

          key={trKey}
          className={
            this.state.highlightedItem === study.studyInstanceUid
              ? 'studylistStudy noselect active'
              : 'studylistStudy noselect'
          }
        >
          {this.getTableCell(study, 'patientId')}
          {this.getTableCell(study, 'patientName', `(${this.props.t('Empty')})`, 'emptyCell')}
          {this.getTableCell(study, 'patientBirthdate')}
          {this.getTableCell(study, 'studyDescription')}
          {this.getTableCell(study, 'studyDate')}
          {this.getTableCell(study, 'studyTime')}
          {this.getTableCell(study, 'acquisitionDateTime')}
          {this.getTableCell(study, 'facility')}
          {/*{getTableCell(study, 'studyDateLoaded')}*/}
          <td className='studylist-actions' style={{display: 'flex'}}>

            <div style={{cursor: 'pointer', margin: '0 5px', color: '#337ab7'}}>
              <Link to={{pathname: `/assign/${study.studyInstanceUid}`}}>
                <Popover content={'Update Study Details'}>
                  <IconAntd style={{fontSize: '18px'}} type='form' />
                </Popover>
              </Link>
            </div>

            {/*<div style={{cursor: 'pointer', margin: '0 5px'}}>
              {study.studyDescription === 'Echo' ?
                <Link to={{pathname: `/report/${study.studyInstanceUid}`}}>
                  <Popover content={'Open Study Report'}>
                    <IconAntd style={{fontSize: '18px'}} type='file'/>
                  </Popover>
                </Link>
                :
                <Popover content={'Open Study Report'}>
                  <IconAntd style={{fontSize: '18px', color: 'rgb(51, 122, 183)'}} type='file' onClick={() => {
                    OHIF.report.fetchStudyIdFromUID({studyInstanceUid: study.studyInstanceUid}).then((result) => {
                      window.open("https://crm.cisbr.com/CardioReportManager/unsecure/studyReport2.aspx?STUDYID=" + result, "_blank", true);
                    });
                  }} />
                </Popover>
              }
            </div>*/}

            <div style={{cursor: 'pointer', margin: '0 5px', color: '#337ab7'}}>
              <Popover content={'Open Study Report'}>
                <IconAntd style={{fontSize: '18px'}} type='file' onClick={() => this.props.redirectToReporting(study.studyInstanceUid)} />
              </Popover>
            </div>
          </td>
        </tr>
      );
    }
  }

  renderTableBody(noListFragment) {
    return !noListFragment && this.props.studies
      ? this.props.studies.map(this.getTableRow.bind(this))
      : null;
  }

  selectDropdown = (field, value) => {
    // Clear search fields.
    //this.setState({ searchData: newFilter }, function () {

    const newFilter = JSON.parse(JSON.stringify(this.state.searchData));
    newFilter.selectedFacilityID = this.props.selectedFacility;
    newFilter.selectedWorklistID = this.props.selectedWorklist;
    newFilter.selectedStatusID = this.props.selectedStatus;
    newFilter.facilityFilterType = this.props.facilityFilterType;
    newFilter[field] = value;

    // Update new selected and use cleared search fields since we are pulling up selected facility.
    this.props.selectFacilityWorklistStatus(newFilter);
    //});
  };

  handleClearSearch = () => {
    this.setState(
      {
        searchData: {
          sortData: {
            field: this.props.defaultSort.field,
            order: this.props.defaultSort.order,
          },
          currentPage: 0,
          rowsPerPage: 10,
          patientId: '',
          patientName: '',
          patientNameLast: '',
          dob: '',
          accessionNumber: '',
          studyDescription: '',
          facility: '',
          physician: '',
          status: '',
          type: '',
          studyDate: [],
          studyDateLoaded: '',
        },
      },
      function () {
        this.props.onSearch();
      }
    );
  };

  onPageChange = (newPage) => {
    const updatedSearchData = {
      ...this.state.searchData,
      currentPage: newPage
    };

    this.props.onSearch(updatedSearchData);
    this.setState({ searchData: updatedSearchData });
  };

  render() {

    const _this = this;
    const tableMeta = getTableMeta(this.props.t);

    // Apply sort
    const sortedFieldName = this.state.searchData.sortData.field;
    const sortedField = tableMeta[sortedFieldName];

    if (sortedField) {
      const sortOrder = this.state.searchData.sortData.order;
      sortedField.sort = sortOrder === 'asc' ? 1 : 2;
    }

    // Sort Icons
    const sortIcons = ['sort', 'sort-up', 'sort-down'];
    const noListFragment = getNoListFragment(
      this.props.t,
      this.props.studies,
      this.state.error,
      this.props.loading || this.state.loading
    );
    const tableBody = this.renderTableBody(noListFragment);
    const studiesNum = this.props.studiesCount;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return (
        <div className="StudyList">
          <div className="studyListToolbar clearfix">
            <div className="header pull-left">{this.props.t('StudyList')}</div>

            <div className="studyCount pull-right">{studiesNum}</div>
            <div className="pull-right">
              {this.props.studyListFunctionsEnabled ? (
                <StudylistToolbar onImport={this.props.onImport} />
              ) : null}
            </div>
            {this.props.children}
          </div>

          {this.props.selectedWorklist !== 0 && this.props.selectedWorklist !== 203 && (
            <Collapse
              style={{
                background: '#151A1F',
                border: 'unset',
                padding: '0 8%',
              }}
              expandIcon={() => false}
              expandIconPosition={'right'}
              // When the panel is opened, the type is set to FacilityID / Reading Facility
              onChange={(activeKeys) => {
                if (activeKeys.includes("1")) {
                  this.setSearchData('type', 'FacilityID');
                } else {
                  this.setSearchData('type', '');
                }
              }}
            >
              <Panel header={<b>advanced search</b>} key="1">
                <div>
                  {Object.keys(tableMeta)
                    .filter(
                      (x) =>
                        x !== 'facility' &&
                        x !== 'studyDateLoaded' &&
                        x !== 'dob' &&
                        x !== 'studyDate' &&
                        x !== 'accessionNumber'
                    )
                    .map((fieldName, i) => {
                      return (
                        <span key={i}>
                          <div id={`_${fieldName}`} className="display-text">
                            <span>{field.displayText}</span>
                          </div>
                          {!field.inputType && (
                            <input
                              placeholder={field.placeholder ? field.placeholder : ''}
                              disabled={!field.enabled}
                              type="text"
                              className="form-control studylist-search"
                              style={{marginBottom: '5px'}}
                              id={fieldName}
                              value={this.state.searchData[fieldName]}
                              onKeyDown={this.onInputKeydown}
                              onChange={this.getChangeHandler(fieldName)}
                              onBlur={this.getBlurHandler(fieldName)}
                            />
                          )}
                          {field.inputType === 'date-range' && (
                            <span>
                              <RangePicker
                                style={{width: '100%'}}
                                format="MM/DD/YYYY"
                                value={this.state.searchData.studyDate}
                                onChange={this.handleDateRangeChange}
                              />
                            </span>
                          )}
                        </span>
                      );
                    })}

                  <table style={{width: '100%'}}>
                    <tbody>
                      <tr>
                        <td className="search-button-row-right">
                          <button className="search-button-homepage" onClick={this.onSearchClick}>
                            Search
                          </button>
                        </td>
                        <td className="search-button-row-left">
                          <button
                            className="search-button-homepage"
                            onClick={this.handleClearSearch}
                          >
                            Clear
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Panel>
            </Collapse>
          )}

          <div className="theadBackground" />
          <div id="studyListContainer">
            <table id="tblStudyList" className="studylistResult table noselect">
              <thead>
                <tr>
                  {Object.keys(tableMeta)
                    .filter(
                      (x) => x === 'patientId' || x === 'patientName' || x === 'studyDescription'
                    )
                    .map((fieldName, i) => {
                      const field = tableMeta[fieldName];
                      return (
                        <React.Fragment key={i}>
                          <th style={{width: 'unset'}}>
                            <div
                              id={`_${fieldName}`}
                              className="display-text"
                              onClick={() => {
                                this.onSortClick(fieldName);
                              }}
                            >
                              <span style={{fontSize: '12px'}}>{field.headerText || field.displayText}</span>
                              <IconAntd name={sortIcons[field.sort]} style={{fontSize: '12px'}} />
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                </tr>
              </thead>
              <tbody id="studyListData" style={{fontSize: '12px'}}>
                {tableBody}
              </tbody>
            </table>

            {noListFragment
              ? noListFragment
              : getPaginationFragment(
                this.props,
                this.state.searchData,
                this.onPageChange,
                this.onPageChange,
                this.onRowsPerPageChange
              )}
          </div>
        </div>
      );
    } else {
      let facilityName = '';
      let defaultFacility = this.props.facilities.find(x => x.Id === this.props.defaultFacility);
      let selectedFacility = this.props.facilities.find(x => x.Id === this.props.selectedFacility);
      if (defaultFacility && defaultFacility.Name) {
        facilityName = defaultFacility.Name;
      }
      if (defaultFacility && defaultFacility.Id === 0 && selectedFacility && selectedFacility.Name) {
        facilityName = selectedFacility.Name;
      }
      return (
        <div className="StudyList">
          <div className="studyListToolbar clearfix">
            <div className="header pull-left">{this.props.t('StudyList')}</div>

            {sessionStorage.getItem('search') ? (
              <div className="worklistFacilityDropDown" style={{marginLeft: '75px'}}>
                <IconAntd style={{color: 'white'}} type="search" /> &nbsp; Default view not
                available while advanced search is active. &nbsp; &nbsp; &nbsp;
                <span
                  style={{color: '#91b9cd', cursor: 'pointer'}}
                  onClick={this.handleClearSearch}
                >
                  Clear
                </span>
              </div>
            ) : (
              <div style={{display: '-webkit-inline-box'}}>
                <Dropdown
                  title={'Facility'}
                  list={this.props.facilities.map((x) => ({
                    title: x.Name,
                    value: x.Id,
                    onClick: function () {
                      _this.selectDropdown('selectedFacilityID', x.Id)
                    }
                  }))}
                />
                <div className="worklistFacilityDropDown">
                  {selectedFacility ? selectedFacility.Name : (this.props.facilities.length > 0 ? this.props.facilities[0].Name : '')}
                </div>

                <Dropdown
                  title={'Worklist'}
                  list={this.props.worklists.map((x, i) => ({
                    key: i,
                    value: x.Id,
                    title: `${x.Name ? x.Name : x.LName + ', ' + x.FName}`,
                    onClick: function () {
                      _this.selectDropdown('selectedWorklistID', x.Id);
                    },
                  }))}
                />
                <div className="worklistFacilityDropDown">
                  {this.props.worklists.length > 0 &&
                    `${this.props.worklists.find((x) => x.Id === this.props.selectedWorklist) ?
                      (
                        this.props.worklists.find((x) => x.Id === this.props.selectedWorklist).Name ?
                          this.props.worklists.find((x) => x.Id === this.props.selectedWorklist).Name
                          :
                          (
                            this.props.worklists.find((x) => x.Id === this.props.selectedWorklist).LName + ', ' +
                            this.props.worklists.find((x) => x.Id === this.props.selectedWorklist).FName
                          )
                      )
                      :
                      'UNDEFINED'
                    }`
                  }
                </div>

                <Dropdown
                  title={'Status'}
                  list={this.props.statuses.map((x, i) => ({
                    key: i,
                    value: x.Id,
                    title: x.Name,
                    onClick: function () {
                      _this.selectDropdown('selectedStatusID', x.Id);
                    },
                  }))}
                />
                <div className="worklistFacilityDropDown">
                  {this.props.statuses.length > 0 &&
                    this.props.statuses.find((x) => x.Id === this.props.selectedStatus).Name}
                </div>

                <Dropdown
                  title={'Type'}
                  list={[
                    {
                      key: 0,
                      value: 'StudyFacilityID',
                      title: 'Study Location',
                      onClick: function () {
                        _this.selectDropdown('facilityFilterType', 'StudyFacilityID')
                      }
                    },
                    {
                      key: 1,
                      value: 'FacilityID',
                      title: 'Reading Facility',
                      onClick: function () {
                        _this.selectDropdown('facilityFilterType', 'FacilityID')
                      }
                    }
                  ]}
                />
                <div className="worklistFacilityDropDown">
                  {
                    this.props.facilityFilterType === 'StudyFacilityID' ?
                      'Study Location'
                      :
                      'Reading Facility'
                  }
                </div>
              </div>
            )}

            <div className="studyCount pull-right">{studiesNum === '65535' ? '65535+' : studiesNum}</div>
            <div className="pull-right">
              {this.props.studyListFunctionsEnabled ? (
                <StudylistToolbar onImport={this.props.onImport} />
              ) : null}
            </div>
            {this.props.children}
          </div>

          {this.props.selectedWorklist !== 203 && (
            <Collapse
              style={{
                background: '#151A1F',
                border: 'unset',
                padding: '0 8%',
              }}
              expandIcon={() => false}
              expandIconPosition={'right'}
              // When the panel is opened, the type is set to FacilityID / Reading Facility
              onChange={(activeKeys) => {
                if (activeKeys.includes("1")) {
                  this.setSearchData('type', 'FacilityID');
                } else {
                  this.setSearchData('type', '');
                }
              }}
            >
              <Panel header={<b>advanced search</b>} key="1">
                <div>
                  <div
                    style={{
                      display: 'inline-block',
                      padding: '3px 15px',
                      width: '25%',
                      verticalAlign: 'top',
                    }}
                  >
                    <div id={`_facility`} className="display-text">
                      Facility
                    </div>
                    <select
                      type="text"
                      className="form-control studylist-search"
                      id="facility"
                      value={this.state.searchData.facility}
                      onChange={(e) => {
                        _this.setSearchData('facility', parseInt(e.target.value), true)
                      }}
                    >
                      {this.props.facilities.map((x, i) => {
                        return (
                          <option key={i} value={x.Id}>
                            {x.Name}
                          </option>
                        )
                      })
                      }
                    </select>
                  </div>

                  <div
                    style={{
                      display: 'inline-block',
                      padding: '3px 15px',
                      width: '25%',
                      verticalAlign: 'top',
                    }}
                  >
                    <div id={`_worklist`} className="display-text">
                      Worklist
                    </div>
                    <select type="text" className="form-control studylist-search" id="physician" value={this.state.searchData.physician} onChange={(e) => {
                        _this.setSearchData('physician', parseInt(e.target.value), true)
                    }}>
                      {this.props.worklists.map((x, i) => {
                        return (
                          <option key={i} value={x.Id}>
                            {x.Name ? x.Name.toUpperCase() : x.LName.toUpperCase() + ', ' + x.FName.toUpperCase()}
                          </option>
                        )
                      })
                      }
                    </select>
                  </div>

                  <div
                    style={{
                      display: 'inline-block',
                      padding: '3px 15px',
                      width: '25%',
                      verticalAlign: 'top',
                    }}
                  >
                    <div id={`_worklist`} className="display-text">
                      Status
                    </div>
                    <select type="text" className="form-control studylist-search" id="status" value={this.state.searchData.status} onChange={(e) => {
                      _this.setSearchData('status', parseInt(e.target.value), true)
                    }}>
                      {
                        this.props.statuses.map((x, i) => {
                          return (
                            <option key={i} value={x.Id}>
                              {x.Name}
                            </option>
                          )
                        })
                      }
                    </select>
                  </div>

                  <div
                    style={{
                      display: 'inline-block',
                      padding: '3px 15px',
                      width: '25%',
                      verticalAlign: 'top',
                    }}
                  >
                    <div id={`_worklist`} className="display-text">
                      Type
                    </div>
                    <select type="text" className="form-control studylist-search" id="status" value={this.state.searchData.type} onChange={(e) => {
                      _this.setSearchData('type', e.target.value, true)
                    }}>
                      <option key={0} value={'StudyFacilityID'}>
                        Study Location
                      </option>
                      <option key={1} value={'FacilityID'}>
                        Reading Facility
                      </option>
                    </select>
                  </div>

                  {Object.keys(tableMeta)
                    .filter((x) => x !== 'facility' && x !== 'studyTime' && x !== 'studyDateTime')
                    .map((fieldName, i) => {
                      const field = tableMeta[fieldName];
                      return (
                        <span
                          key={i}
                          style={{
                            display: 'inline-block',
                            padding: '3px 15px',
                            width: '25%',
                            verticalAlign: 'top',
                          }}
                        >
                          <div id={`_${fieldName}`} className="display-text">
                            <span>{field.displayText}</span>
                          </div>
                          {!field.inputType && (
                            <input
                              key={i}
                              placeholder={field.placeholder ? field.placeholder : ''}
                              disabled={!field.enabled}
                              type="text"
                              className="form-control studylist-search"
                              id={fieldName}
                              value={this.state.searchData[fieldName]}
                              onKeyDown={this.onInputKeydown}
                              onChange={this.getChangeHandler(fieldName)}
                              onBlur={this.getBlurHandler(fieldName)}
                            />
                          )}
                          {field.inputType === 'date-range' && (
                            <span>
                              <RangePicker
                                style={{width: '100%'}}
                                format="MM/DD/YYYY"
                                value={this.state.searchData.studyDate}
                                onChange={this.handleDateRangeChange}
                              />
                            </span>
                          )}

                          {field.inputType === 'dropdown' && (
                            <select type="text" className="form-control studylist-search" id="facility" value={this.state.searchData[fieldName]} onChange={(e) => {e.target.value === 'All' ? this.setSearchData('studyDescription', '', true) : this.setSearchData('studyDescription', e.target.value, true)}}>
                              <option key={'all'} value={'All'}>All</option>
                              {this.props[field.propsVariable].map((x, i) => {
                                return (
                                  <option key={i} value={x.Name}>
                                    {x.Name}
                                  </option>
                                )
                              })
                              }
                            </select>
                          )}
                        </span>
                      );
                    })}

                  <table style={{width: '100%'}}>
                    <tbody>
                      <tr>
                        <td className="search-button-row-right">
                          <button className="search-button-homepage" onClick={this.onSearchClick}>
                            Search
                          </button>
                        </td>
                        <td className="search-button-row-left">
                          <button
                            className="search-button-homepage"
                            onClick={this.handleClearSearch}
                          >
                            Clear
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Panel>
            </Collapse>
          )}

          <div className="theadBackground" />
          <div id="studyListContainer">
            <table id="tblStudyList" className="studylistResult table noselect">
              <thead>
                <tr>
                  {Object.keys(tableMeta).filter(x => x !== 'patientNameLast').map((fieldName, i) => {
                    const field = tableMeta[fieldName];

                    return (
                      <React.Fragment key={i}>
                        <th style={{width: 'unset'}}>
                          <div
                            id={`_${fieldName}`}
                            className="display-text"
                            onClick={() => {
                              this.onSortClick(fieldName);
                            }}
                          >
                            <span>{field.headerText || field.displayText}</span>
                            <IconAntd name={sortIcons[field.sort]} style={{fontSize: '12px'}} />
                          </div>
                        </th>
                      </React.Fragment>
                    );
                  })}
                </tr>
              </thead>
              <tbody id="studyListData">{tableBody}</tbody>
            </table>

            {noListFragment
              ? noListFragment
              : getPaginationFragment(
                this.props,
                this.state.searchData,
                this.onPageChange,
                this.onPageChange,
                this.onRowsPerPageChange
              )}

            {/* <div className="browser-cache-container">
              <span>Browser cache&nbsp;&nbsp;&nbsp;-&nbsp;</span>
              <Button
                className="browser-cache-button"
                id="fast"
                type="link"
                icon={this.state.browserCache === 'fast' ? 'thunderbolt' : ''}
                size="small"
                onClick={this.handleChangeViewerCache}
              >
                Fast
              </Button>
              <Button
                className="browser-cache-button"
                id="efficient"
                type="link"
                icon={this.state.browserCache === 'efficient' ? 'tool' : ''}
                size="small"
                shape="round"
                onClick={this.handleChangeViewerCache}
              >
                Efficient
              </Button>
            </div> */}
          </div>
        </div>
      );
    }
  }
}


const connectedComponent = withTranslation('StudyList')(StudyList);


const mapStateToProps = (state) => {
  const activeServer = state.servers.servers.find((a) => a.active === true);

  return {
    currentPage: state.viewer.currentPage,
    server: activeServer,
    viewer: state.viewer,
  };
};

const connectedComponent2 = connect(mapStateToProps)(connectedComponent);

export {connectedComponent2 as StudyList};
